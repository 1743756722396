




import { Component, Vue } from "vue-property-decorator";
import ko from "knockout";
import koHtmlAndViewModel from "Components/long-trend-config/long-trend-config";
import { LongTrendConfig } from "@/types/longTrendConfig";

@Component
export default class LongTrendConfigWrapper extends Vue {
  instance: any = {};

  mounted(): void {
    this.instance = new koHtmlAndViewModel.viewModel();
    const { template } = koHtmlAndViewModel;
    this.$el.innerHTML = template;

    this.instance.onSubmit = (config: LongTrendConfig) => {
      this.$emit("submit", config);
    };

    ko.applyBindings(this.instance, this.$el);
  }

  updateTimePeriod(fromDateTime: number, toDateTime: number): void {
    this.instance.updateTimePeriod(fromDateTime, toDateTime);
  }

  beforeDestroy(): void {
    ko.cleanNode(this.$el);
    this.instance.dispose();
  }
}
