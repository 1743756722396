
















































import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import { LongTrendData } from "@/types/longTrendData";
import { LongTrendConfig } from "@/types/longTrendConfig";
import LongTrendsClient from "Clients/long-trends-client";
import { Chart } from "highcharts-vue";
import Highcharts from "highcharts";
import HighchartsNoData from "highcharts/modules/no-data-to-display";
HighchartsNoData(Highcharts);

@Component({
  components: {
    HighCharts: Chart,
  },
})
export default class LongTrendGraph extends Vue {
  @Prop({ type: String }) readonly longTrendIds?: string;
  @Prop({ type: Object }) readonly config?: LongTrendConfig;

  @Ref() readonly chart!: any;

  longTrendsData: LongTrendData[] | null = null;
  loadingState: "idle" | "loading" = "idle";
  graphZoomed = false;
  selectedXAxisMin = null;
  selectedXAxisMax = null;

  get isLoading(): boolean {
    return this.loadingState === "loading";
  }

  get hasLongTrend(): boolean {
    return this.longTrendsData !== null;
  }

  @Watch("config", { immediate: true, deep: true })
  async onConfigChange(): Promise<void> {
    this.loadingState = "loading";
    this.graphZoomed = false;

    try {
      this.longTrendsData = await LongTrendsClient.getLongTrendData(this.longTrendIds);
    } finally {
      this.loadingState = "idle";
    }
  }

  get chartOptions(): unknown {
    return {
      time: {
        useUTC: false,
      },
      credits: {
        enabled: false,
      },
      chart: {
        marginTop: 50,
        type: "line",
        zoomType: "x",
        resetZoomButton: {
          theme: {
            display: "none",
          },
        },
        events: {
          //Updates the average value in legend when zooming on the graph
          selection: function (this: any, event: any) {
            setTimeout(() => {
              if (event.resetSelection) {
                this.graphZoomed = false;
              } else {
                this.graphZoomed = true;
              }

              //Custom code to update averge value in label when zooming the graph
              this.selectedXAxisMin = event.target.xAxis[0].min;
              this.selectedXAxisMax = event.target.xAxis[0].max;

              const xAxisMin = Math.ceil(event.target.xAxis[0].min);
              const xAxisMax = Math.ceil(event.target.xAxis[0].max);
              const seriesList = event.target.series;
              const selectedSeriesList = seriesList.map((series: any, index: number) => {
                return {
                  longTrendData: series.data.filter((point: any) => point.x >= xAxisMin && point.x <= xAxisMax),
                  unit: this.longTrendsData[index].unit,
                  longTrendName: this.longTrendsData[index].longTrendName,
                };
              });

              for (const i in selectedSeriesList) {
                seriesList[i].update(
                  {
                    name: this.averageValue(selectedSeriesList[i]),
                  },
                  true
                );
              }
            });
          }.bind(this),
        },
        style: {
          fontFamily: "Arial",
        },
      },
      title: {
        text: "",
      },
      yAxis: this.longTrendsData?.map((longTrendData, index) => ({
        title: {
          text: "",
          style: { color: this.yAxisColors[index] },
        },
        labels: {
          format: "{value} " + longTrendData?.unit.caption,
          style: {
            color: this.yAxisColors[index],
          },
        },
      })),
      xAxis: {
        type: "datetime",
        showEmpty: false,
      },
      series: this.longTrendsData?.map((longTrendData, index) => ({
        name: longTrendData.longTrendName + this.averageValue(longTrendData),
        lineWidth: 2,
        yAxis: index,
        data: longTrendData.longTrendData?.map(data => [Date.parse(data.x), data.y ? Number(data.y.toFixed(2)) : null]),
      })),
      noData: {
        style: {
          color: "#331714",
        },
      },
      lang: {
        noData: "Found no data for selected time period",
      },
    };
  }

  //Highcharts only provide built in colors for the x-axis, so here we manually set the colors of the y-axis to match.
  get yAxisColors(): string[] {
    return ["#7cb5ec", "#434348", "#90ed7d", "#f7a35c", "#8085e9"];
  }

  resetZoom(): void {
    this.chart.chart.zoomOut();
  }

  averageValue(longTrendData: LongTrendData): string {
    const dataArray = longTrendData ? longTrendData.longTrendData.map(dataEntry => dataEntry.y) : [];
    let value = 0;
    let counter = 0;

    dataArray.forEach(function (dataPoint) {
      if (dataPoint !== null) {
        value += dataPoint;
        counter++;
      }
    });

    return counter > 0 ? ` (Avg: ${Number((value / counter).toFixed(2))} ${longTrendData.unit.name})` : " (No data)";
  }

  updateTimePeriod(): void {
    this.$emit("updateTimePeriod", { fromDateTime: this.selectedXAxisMin, toDateTime: this.selectedXAxisMax });
  }
}
