















































import { Component, Vue, Ref } from "vue-property-decorator";
import { Route } from "vue-router";
import { getModule } from "vuex-module-decorators";
import Store from "@/store";
import LongTrendConfigWrapper from "@/components/knockoutWrappers/LongTrendConfigWrapper.vue";
import FeatureNotAvailable from "@/components/FeatureNotAvailable.vue";
import LongTrendGraph from "@/components/LongTrendGraph.vue";
import VesselsModule from "@/store/clients/Vessels.module";
import { LongTrendConfig } from "@/types/longTrendConfig";
import { ExtendedVessel } from "@/types/Vessel";
import LongTrendsClient from "@/Scripts/clients/long-trends-client";

const Vessels = getModule(VesselsModule, Store);

@Component({
  components: {
    LongTrendConfigWrapper,
    FeatureNotAvailable,
    LongTrendGraph,
  },
  beforeRouteEnter(to: Route, from: Route, next): void {
    function showLongTrend(vessel: ExtendedVessel | null): boolean {
      return Boolean(!vessel?.isDiagnosticActivated && vessel?.trendDataMinDate && vessel.trendDataMaxDate);
    }

    //Case: switching to a vessel from sidebar where long trend is not a menu item - redirect to 'Info'
    if (showLongTrend(Vessels.currentVessel)) {
      next();
    } else {
      next({ name: "Info", replace: true });
    }
  },
})
export default class LongTrend extends Vue {
  vessel = Vessels.currentVessel;
  configPanelOpen: 0 | null = 0;
  longTrendIds: string[] = [];
  config: LongTrendConfig | null = null;

  @Ref() readonly wrapper!: LongTrendConfigWrapper;

  get featureEnabled(): boolean {
    if (!this.vessel) return false;

    return this.vessel.features.some(feature => feature.name === "LongTrend");
  }

  onSubmit(config: LongTrendConfig): void {
    this.config = config;
    this.longTrendIds = config.longTrendIds.split(/\||;/) ?? [];

    //If there are no group graphs, the last element will be an empty string we need to remove
    if (!this.longTrendIds[this.longTrendIds.length - 1]) {
      this.longTrendIds.pop();
    }

    //If there are no distinct graphs, the first element will be an empty string we need to remove
    if (!this.longTrendIds[0]) {
      this.longTrendIds.shift();
    }

    this.configPanelOpen = null;
  }

  onUpdateTimePeriod({ fromDateTime, toDateTime }: { fromDateTime: number; toDateTime: number }): void {
    this.wrapper.updateTimePeriod(fromDateTime, toDateTime);
  }

  downloadExcel(): void {
    LongTrendsClient.downloadExcel(Vessels.currentVessel?.id);
  }
}
